<script>
	import hhstore from './HHStore'
	import {
		postf
	} from '../store/index.js'

	import store from '../store'

	export default {
		async click_log(name, src) {
			await postf({
				url: "user/click_log",
				data: {
					phone: hhstore.get("phone"),
					url: src,
					title: name,
				}
			})
		},

		async getdot() {
			var uid = hhstore.get("uid");
			var token = hhstore.get("token");
			let data = await postf({
				url: 'user/dot',
				data: {
					"uid": uid,
					"token": token
				},
			});

			store.commit('SET_DOT', data)
		}
	}
</script>
