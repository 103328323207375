<template>
	<div class="bg">
		<div class="content">
			<div class="top">
				<img :src="info.img" />
				<span>{{info.title}}</span>
			</div>
			<div class="haoma">充值号码</div>
			<div class="ipt"><input type="text" name="phone" :placeholder="info.tishi" v-model="account"></div>

			<div class="fenge"></div>

			<template v-for="(value,key,index) in kvs">
				<div class="haoma">{{key}}</div>
				<div class="miane clearfix">
					<div class="mianeitem" v-for="(v,i) in kvs[key]" v-on:click="kvclick(key,v)"
						v-bind:class="{mianeselect:v==selectedsku[key]}"
						v-if="!(Object.keys(kvs).length -1 == index && price(key,v).length==0)">
						<div class="mianeiteml1">{{v}}</div>
						<template v-if="Object.keys(kvs).length -1 == index">
							<div class="mianeiteml2">售价¥{{price(key,v)}}</div>
							<div class="mianeiteml3">¥{{originPrice(key,v)}}</div>
						</template>
					</div>
				</div>
			</template>

			<div class="xuzhi">
				<p>购买须知</p>
				<div v-if="info.xuzhi_html" v-html="info.xuzhi_html"></div>
				<pre v-else>{{info.xuzhi}}</pre>
			</div>

			<div style="height: 20px;"></div>
		</div>

		<div class="btndiv">
			<button class="btndivleft" v-on:click="onorder()">我的订单</button>
			<button class="btndivright" v-on:click="onbuy()">确认充值</button>
		</div>

		<div class="kefudiv" @click="handlekefu"> <img src="https://img.zhsyh.cn/mp-qyw/kefu.png" /></div>

	</div>
</template>
<script>
	import {
		mapActions,
		mapState
	} from 'vuex'
	import validate from '../utils/Validate'
	import hhstore from '../utils/HHStore'
	import urlParams from '../utils/UrlParams'
	import {
		Toast,
		Dialog
	} from 'vant';
	import wxutils from '../utils/Wx'

	/* 给小b充值的页面，微信授权直接充值，不需要权益王用户登录 */

	export default {
		name: 'Pay',
		components: {
			[Dialog.Component.name]: Dialog.Component,
		},
		data: function() {
			return {
				phone: '',
				sku_id: '',
				goods_id: 5,

				info: {},
				kvs: {},
				sku: null,
				selectedsku: {},
				img: "",
				account: "",

				appid: "",
				oncestr: "",
				sign: "",
				timestamp: "",

				gzhid: ''
			}
		},
		props: {

		},
		beforeMount() {

		},
		mounted() {
			//测试用
			// hhstore.set('tokenxb', '8zCAtrBjBTEmFWCPWzc8')

			if (process.env.NODE_ENV === "development") {
				this.gzhid = 'wxfbd9f6d6aa3236be' // 票虎
			} else {
				this.gzhid = 'wx1a5b1b2a6bd07ea0' //权益王
			}

			if (!hhstore.get("tokenxb")) {
				var code = urlParams.get('code');
				if (code) {
					this.loginWithCode(code);
				} else {
					wxutils.auth(this.gzhid);
					return
				}
			}

			this.goods_id = urlParams.get('gid') ? urlParams.get('gid') : ''
			this.appid = urlParams.get('appid') ? urlParams.get('appid') : ''
			this.oncestr = urlParams.get('oncestr') ? urlParams.get('oncestr') : ''
			this.sign = urlParams.get('sign') ? urlParams.get('sign') : ''
			this.timestamp = urlParams.get('timestamp') ? urlParams.get('timestamp') : ''

			this.getDetail()
		},
		beforeDestroy() {

		},
		computed: {
			...mapState([])
		},
		methods: {
			...mapActions(['post']),
			loginWithCode(code) {
				this.post({
					url: "xiaobi/login_with_code",
					data: {
						"code": code,
					},
					success: (data) => {
						console.info(data)
						hhstore.set("uidxb", data.uid)
						hhstore.set("tokenxb", data.token)
					},
					error: (code, msg) => {
						// 微信登录失败，返回注册页面
						Toast.fail("未知错误，请联系客服")
					}
				});

			},
			price(k, v) {
				return this._price(k, v, "price")
			},
			originPrice(k, v) {
				return this._price(k, v, "original_price")
			},
			_price(k, v, item) {
				for (var i = this.sku.length - 1; i >= 0; i--) {
					const specs = JSON.parse(this.sku[i].specs)
					let check = true
					Object.keys(specs).forEach((key) => {
						if (k == key) {
							if (!(v == specs[key])) {
								check = false
							}
						} else {
							if (!(this.selectedsku && this.selectedsku[key] == specs[key])) {
								check = false
							}
						}
					})
					if (check) {
						return this.sku[i][item] / 100;
					}
				}
				return "";
			},
			getDetail() {
				var self = this
				this.post({
					url: "goods/detailxb",
					data: {
						goods_id: this.goods_id,
						appid: this.appid,
						oncestr: this.oncestr,
						sign: this.sign,
						timestamp: this.timestamp,
					},
					success: function(data) {
						// data.info.xuzhi = data.info.xuzhi.replace(/\r\n/g, "<br>")
						// data.info.xuzhi = data.info.xuzhi.replace(/\n/g, "<br>")
						self.kvs = data.kvs
						self.info = data.info
						self.sku = data.sku
						document.title = data.info.title

						Object.keys(data.kvs).forEach((key, index) => {
							// if (index != Object.keys(data.kvs).length-1) {
							self.selectedsku[key] = data.kvs[key][0]
							// }
						})

					},
					error: function(code, err) {
						Toast.fail(err);
					}
				});
			},
			handlekefu() {
				window.location.href =
					'https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b97877600947441c8e71ce4b831abaac5e200c6d00ff766c1d4f753856f32fc3c76764b8a4fd5bc6567fe76012876cd1ec0'
			},
			kvclick(key, value) {
				if (!this.selectedsku) {
					this.selectedsku = {};
				}
				this.selectedsku[key] = value
				this.$forceUpdate()
			},
			onorder() {
				this.$router.push('/orderxb')
			},
			onbuy: function() {
				const phone = this.account.trim();
				if (phone.length == 0) {
					Toast.fail("请填写充值号码")
					return
				}

				// const r = /^\d+$/;
				// if (!phone.match(r)) {
				// 	Toast.fail("充值号码格式不正确")
				// 	return
				// }

				for (var key in this.kvs) {
					if (!this.selectedsku.hasOwnProperty(key)) {
						Toast.fail('请选择' + key);
						return;
					}
				}

				let sku_id = null
				for (var i = this.sku.length - 1; i >= 0; i--) {
					const specs = JSON.parse(this.sku[i].specs)
					let check = true
					Object.keys(specs).forEach((key) => {
						if (!(this.selectedsku && this.selectedsku[key] == specs[key])) {
							check = false
						}
					})
					if (check) {
						sku_id = this.sku[i].id;
					}
				}

				if (!sku_id) {
					Toast.fail("请选择需要充值的菜单");
					return;
				}

				this.sku_id = sku_id
				this.confirmonbuy()
			},
			confirmonbuy() {
				var phone = this.account.trim()

				this.post({
					url: 'xiaobi/checkcf',
					data: {
						'account': phone,
						"uid": hhstore.get("uidxb"),
						"token": hhstore.get("tokenxb"),
					},
					success: (data) => {
						if (data == 1) {
							Dialog.confirm({
								message: '该账户已有充值记录，是否继续充值？',
							}).then(() => {
								this.xiadan()
							}).catch(() => {
								// on cancel
							});
						} else {
							this.xiadan()
						}
					},
					error: (code, err) => {
						if (code == 952167 || code == 549456 || code == 646985) { //微信未授权
							hhstore.del("tokenxb")
							hhstore.del("uidxb")
							wxutils.auth(this.gzhid);
						} else {
							Toast.fail(err);
						}
					}
				});
			},
			xiadan() {
				var phone = this.account.trim()
				const data = {
					"goods_id": this.goods_id,
					"sku_id": this.sku_id,
					"uid": hhstore.get("uidxb"),
					"token": hhstore.get("tokenxb"),
					"data": {
						"phone": phone
					}
				};

				const UA = navigator.userAgent.toLowerCase()
				const inWechat = UA.match(/.*?(micromessenger\/([0-9.]+))\s*/)
				const inDingDing = UA.match(/.*?(dingtalk\/([0-9.]+))\s*/)
				const cardType = hhstore.get('card_type')

				var url = "wx/xiadan_xiaobi";

				this.post({
					url: url,
					data: data,
					success: (data) => {
						this.dobuy(data)
					},
					error: (code, err) => {
						if (code == 952167 || code == 549456 || code == 646985) { //微信未授权
							hhstore.del("tokenxb")
							hhstore.del("uidxb")
							wxutils.auth(this.gzhid);
						} else {
							Toast.fail(err);
						}
					}
				});
			},
			dobuy(data) {
				var self = this;
				WeixinJSBridge.invoke('getBrandWCPayRequest', data,
					function(res) {
						if (res.err_msg == "get_brand_wcpay_request:ok") {
							// 使用以上方式判断前端返回,微信团队郑重提示：
							//res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
							Toast.success("购买成功");
							setTimeout(function() {
								self.$router.push('/orderxb')
							}, 3000);

						} else {
							Toast.fail("购买失败");
						}
					}
				);
			},

		}
	}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	body {
		text-align: center;

	}

	.bg {
		background-color: #fff;
		height: 100vh;
	}

	.btndiv {
		position: absolute;
		bottom: 0px;
		left: 0px;
	}

	.btndivleft {
		width: 50vw;
		height: 4rem;
		background-color: #ffffff;
		border-width: 0px;
		color: #333333;
		font-size: 1rem;
	}

	.btndivright {
		width: 50vw;
		height: 4rem;
		background-color: #4F4F4F;
		border-width: 0px;
		color: #B2ACA3;
		font-size: 1rem;
	}

	.top {
		height: 4.375rem;
		background-image: linear-gradient(to right, #F9F4E0, #F3EACC);
		line-height: 4.375rem;
		font-size: 1rem;
		font-weight: 800;
		color: #000000;
	}

	.top img {
		margin-left: 1.25rem;
		margin-top: 0.625rem;
		width: 3.125rem;
		height: 3.125rem;
		float: left;
	}

	.top span {
		margin-left: 0.3rem;
	}

	.haoma {
		clear: both;
		font-size: 0.76rem;
		margin-top: 1.6rem;
		margin-left: 0.93rem;
	}

	.ipt {
		font-size: 1.32rem;
		margin-top: 0.625rem;
		margin-left: 0.93rem;
		margin-bottom: 0.9375rem;
	}


	.xuzhi {
		font-size: 0.83rem;
		margin-left: 0.93rem;
		background-color: #FCF7E9;
		color: #C7AC7C;
		width: 85vw;
		margin-top: 1rem;
		padding-right: 0.93rem;
		padding-top: 0.1rem;
		padding-bottom: 1rem;
		padding-left: 0.93rem;
	}

	.fenge {
		height: 0.53rem;
		background-color: #F5F5F5;
		margin-top: 0.8rem;
	}

	.miane {
		margin-left: 0.93rem;
		margin-top: 0.6rem;
	}

	.mianeitem {
		float: left;
		/*height: 6.66rem;
    width: 6.66rem;;*/
		padding-left: 1rem;
		padding-right: 1rem;
		padding-top: 1rem;
		padding-bottom: 1rem;
		text-align: center;
		margin-left: 0.5rem;
		margin-bottom: 0.5rem;
		border-radius: 5px;
		border-width: 1px;
		border-color: #cccccc;
		border-style: solid;
		font-size: 0.66rem;
	}

	.mianeselect {
		border-color: #E7D1B5;
		box-shadow: #FCF7E9 0px 0px 3px 3px;
		color: #CF7055;
		background-color: #FDFCF7;
	}

	.mianeiteml1 {
		font-size: 0.875rem;

		/*margin-top: 1.36rem;*/
	}

	.mianeiteml2 {
		font-size: 0.86rem;
		margin-top: 0.33rem;
		color: #2c3e50;
	}

	.mianeiteml3 {
		margin-top: 0.33rem;
		text-decoration: line-through;
		color: #c1c1c1;
	}

	.clearfix:before,
	.clearfix:after {
		content: "";
		display: table;
	}

	.clearfix:after {
		clear: both;
	}

	.clearfix {
		zoom: 1
	}

	pre {
		white-space: pre-wrap;
		/*css-3*/
		white-space: -moz-pre-wrap;
		/*Mozilla,since1999*/
		white-space: -pre-wrap;
		/*Opera4-6*/
		white-space: -o-pre-wrap;
		/*Opera7*/
		word-wrap: break-word;
		/*InternetExplorer5.5+*/
	}

	.content {
		overflow: scroll;
		height: calc(100vh - 4rem);
		height: -webkit-calc(100vh - 4rem);
		height: -moz-calc(100vh - 4rem);
	}

	.dialog-title {
		color: white;
		height: 3.375rem;
		line-height: 3.375rem;
		text-align: center;
		font-size: 0.875rem;
		background-image: linear-gradient(to right, #F1A259, #ED6B80);
	}

	.dialog-text {
		margin: 0rem 0.8125rem 0.625rem 0.8125rem;
		font-size: 0.8125rem;
	}

	.dialog-text2 {
		font-size: 1.125rem;
		color: red;
		font-weight: bold;
	}

	.dialog-text3 {
		height: 2.75rem;
		background-color: #F6F6F6;
		border-radius: 1.375rem;
		margin-left: 0.8125rem;
		margin-right: 0.8125rem;
		margin-top: 1.25rem;
		margin-bottom: 1.25rem;
		text-align: center;
		line-height: 2.75rem;
		font-size: 1.375rem;
	}

	.kefudiv {
		position: absolute;
		left: 1rem;
		bottom: 5rem;
	}

	.kefudiv img {
		width: 3.5rem;
	}
</style>
<style>
	.ql-size-small {
		font-size: 0.75em;
	}

	.ql-size-large {
		font-size: 1.5em;
	}

	.ql-size-huge {
		font-size: 2.5em;
	}

	.ipt input {
		border-width: 0px;
		border-bottom-width: 1px;
		background-color: rgba(0, 0, 0, 0);
		height: 2.5rem;
		border-bottom-color: #c1c1c1;
		color: #333;
	}
</style>
