<template>
	<div class="bg">
		<button @click="clear" style="width: 100px;height: 50px;margin-top:100px;">清除缓存</button>
	</div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import validate from '../utils/Validate'
import hhstore from '../utils/HHStore'
import urlParams from '../utils/UrlParams'
import { Toast } from 'vant';

/* 用来微信授权用 ，授权完成后，根据  localStorage.auth_back 地址，跳回原网址 */

export default {
	name: 'Clear',
	data: function () {
		return {
			list: null
		}
	},
	props: {

	},
	beforeMount() {

	},
	mounted() {

	},
	beforeDestroy() {

	},
	computed: {
		...mapState([])
	},
	methods: {
		...mapActions(['post']),

		clear() {
			hhstore.del('tokenxb')
			hhstore.del("uidxb")
			Toast.success("清除成功");

		}
	}
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg {
	display: flex;
	justify-content: center;
}

</style>