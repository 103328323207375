import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueRouter from 'vue-router' // 引入vue-router
import '../static/res/js/rem'

import Xbcz from '@/views/Xbcz'
import Clear from '@/views/Clear'
import Orderxb from '@/views/Orderxb'

Vue.config.productionTip = false
Vue.config.ignoredElements = [
	'wx-open-launch-weapp'
]

Vue.use(VueRouter) //Vue全局使用Router

const routes = [{
		path: '/',
		component: Xbcz,
		meta: {
			title: '',
		}
	},
	{
		path: '/clear',
		component: Clear,
		meta: {
			title: '',
		}
	},
	{
		path: '/orderxb',
		component: Orderxb,
		meta: {
			title: '我的订单'
		}
	},
]

const router = new VueRouter({
	mode: 'history',
	routes
})

router.beforeEach((toRoute, fromRoute, next) => {
	window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : '';
	next();
})


new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
