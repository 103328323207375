<template>
	<div id="app">
		<router-view></router-view>

		<van-tabbar route v-if="$route.meta.showTab" active-color="#6F241A" inactive-color="#999999">
			<van-tabbar-item replace :to="homepage">
				<span>首页</span>
				<template #icon="props">
					<img :src="props.active ? homeicon.active : homeicon.inactive" />
				</template>
			</van-tabbar-item>
			<van-tabbar-item replace to="/me" :dot="dot.me">
				<span>我的</span>
				<template #icon="props">
					<img :src="props.active ? usericon.active : usericon.inactive" />
				</template>
			</van-tabbar-item>
		</van-tabbar>

		<div class="toast" v-if='$store.state.toast'>
			<div class="toast_text">
				{{$store.state.text}}
			</div>
		</div>
	</div>
</template>
<script>
	import hhstore from './utils/HHStore'
	import help from './utils/Help'
	import {
		Tabbar,
		TabbarItem,
	} from 'vant';

	import {
		mapActions,
		mapState,
		store
	} from 'vuex'

	export default {
		name: 'App',
		components: {
			[Tabbar.name]: Tabbar,
			[TabbarItem.name]: TabbarItem,
		},
		computed: {
			...mapState(['homepage', 'dot']),
		},
		data: function() {
			return {
				homeicon: {
					active: "http://img.zhsyh.cn/h5/tab/homeHL.png",
					inactive: "http://img.zhsyh.cn/h5/tab/home.png",
				},
				usericon: {
					active: "http://img.zhsyh.cn/h5/tab/userHL.png",
					inactive: "http://img.zhsyh.cn/h5/tab/user.png",
				}
			}
		},
		created() {
			if (hhstore.get('homepage')) {
				this.$store.commit('SET_HOMEPAGE', hhstore.get('homepage'))
			}

			if (hhstore.get('kefu')) {
				this.$store.commit('SET_KEFU', hhstore.get('kefu'))
			}
		},
		methods: {
			...mapActions(['post', 'syncpost']),
		},
	}
</script>

<style>
	#app {
		font-family: 'PingFang Regular';
		color: #2c3e50;
		background: #F8F8F8;
	}

	body {
		margin: 0px;
		background: #F8F8F8;

	}

	.toast {
		position: fixed;
		/* height: 100vh;
  width:100vw; */
		background-color: rgba(0, 0, 0, .2);
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		color: #fff
	}

	.toast_text {
		text-align: center;
		transform: translate(-50%, -50%);
		position: absolute;
		top: 50%;
		left: 50%;
		width: 18rem;
		padding: 1rem;
		background: rgba(0, 0, 0, 0.6);
		border-radius: 0.4rem;
		font-size: 1.067rem;
		margin: 0 auto;
	}
</style>
